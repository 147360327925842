import './scss/style.scss';

class AhojPay {
    constructor() {
        const domain = new URL(document.currentScript.src).host;

        this.appName = 'AhojPay';
        this.version = '2.0.0';
        this.promotionInfo = null;
        this.isProd = domain.indexOf('eshop.ahoj.shopping') >= 0 || domain.indexOf('eshop.ahojsplatky.sk') >= 0;

        // gtag
        this.gtagMeasurementId = this.isProd ? 'G-YG6NJVCYJQ' : 'G-BGHS9YJ9Y9';
        this.GTAG_SHOW_BANNER_EVENT = 'show_product_banner';
        this.GTAG_SHOW_PAYMENT_METHOD_DESCR_EVENT = 'show_payment_method_description';
        this.GTAG_SHOW_HOW_IT_WORKS_EVENT = 'show_how_it_works';
        // storage for events before gtag is initialized
        this.gtagBuffer = [];
        this.gtagInitialized = false;

        // productTypes
        this.PRODUCT_TYPE_CODE_ODLOZTO = 'GOODS_DEFERRED_PAYMENT';
        this.PRODUCT_TYPE_CODE_ROZLOZTO = 'GOODS_SPLIT_PAYMENT';

        this.PROMOTION_CODE_ODLOZTO = 'DP_DEFER_IT';

        this._includeCssStyles();

        document.addEventListener('DOMContentLoaded', () => {
            this._includeAnalyticsJs();
            this._subscribeToMessages();
        });

        this.productBannerTitleCssClass = 'product-banner__title';
        this.IFRAME_MODAL_ID = 'ahoj-pay-iframe-modal';

        this.MORE_INFO_LINK = 'https://ahoj.shopping/podmienky-odlozenej-platby';
        this.MORE_INFO_LINK_MAP = {};
        this.MORE_INFO_LINK_MAP[this.PRODUCT_TYPE_CODE_ODLOZTO] = 'https://ahoj.shopping/podmienky-odlozenej-platby';
        this.MORE_INFO_LINK_MAP[this.PRODUCT_TYPE_CODE_ROZLOZTO] = 'https://ahoj.shopping/podmienky-3platby';
    }

    /**
     * Inicializacia JS objektu AhojPay s datami zo server side.
     *
     * Priklad:
     * <script type="text/javascript">
     * var promotionInfo = <sem pridu data, ktore vypluje PHP funkcia getPromotionInfo()>
     * ahojpay.init(promotionInfo);
     * </script>
     *
     * @param object promotionInfo Data, ktore vypluje PHP funkcia getPromotionInfo()
     */
    init(promotionInfo, disableAnalytics) {
        if (!this._isObject(promotionInfo)) {
            this._logError(`Provided promotionInfo is not valid - ${promotionInfo}`);
            return;
        }

        // support legacy (1.5 plugin version doesn't return `productType`, but it can be caught by `code`)
        if (typeof promotionInfo.productType === 'undefined') {
            promotionInfo.productType =
                promotionInfo.code === 'DP_DEFER_IT' ? 'GOODS_DEFERRED_PAYMENT' : 'GOODS_SPLIT_PAYMENT';
        }

        this.promotionInfo = promotionInfo;
        if (disableAnalytics) {
            this.disableAnalytics();
        }
    }

    /**
     * Funkcia otvori iframe, v ktorom bude zobrazena ziadost.
     *
     * Priklad:
     * <script type="text/javascript">
     * var promotionInfo = <sem pridu data, ktore vypluje PHP funkcia getPromotionInfo()>;
     * var applicationUrl = <sem pride hodnota applicationUrl, ktora je vracana PHP funkciou createApplocation(...)>;
     * ahojpay.init(promotionInfo);
     * ahojpay.openApplication(applicationUrl);
     * </script>
     *
     * @param string applicationUrl Application URL, ktoru vrati PHP funkcia createApplocation(...)
     */
    openApplication(applicationUrl) {
        if (!applicationUrl) {
            this._logError(`Provided applicationUrl is not valid - ${applicationUrl}`);
            return;
        }
        this._createApplicationIframeModal(applicationUrl);
    }

    /**
     * Funkcia na vykreslenie produktoveho bannera
     *
     * Priklad:
     * <script type="text/javascript">
     * var promotionInfo = <sem pridu data, ktore vypluje PHP funkcia getPromotionInfo()>;
     * ahojpay.init(promotionInfo);
     * ahojpay.productBanner(123.45, '#product-banner-wrapper');
     * </script>
     *
     * @param string price Cena produktu.
     * @param string selector CSS selector HTML elementu, do ktoreho bude vykresleny produktovy banner.
     * @param array calculations Kalkulacie pochadzajuce z volania PHP metody getCalculations
     */
    productBanner(price, selector, calculations = []) {
        const existingProductBanner = document.querySelector(`${selector} .product-banner`);
        const productBannerExists = typeof existingProductBanner != undefined && existingProductBanner != null;

        if (productBannerExists) {
            existingProductBanner.remove();
        }

        if (!this.isAnyProductAvailableForItemPrice(price)) {
            return;
        }

        const newProductBanner = this._createProductBannerElement(price, calculations);
        this._gtag('event', this.GTAG_SHOW_BANNER_EVENT);
        document.querySelector(selector).appendChild(newProductBanner);
    }

    /**
     * Funkcia na vykreslenie popisu k platobnej metode
     *
     * Priklad:
     * <script type="text/javascript">
     * var promotionInfo = <sem pridu data, ktore vypluje PHP funkcia getPromotionInfo()>;
     * ahojpay.init(promotionInfo);
     * ahojpay.paymentMethodDescription(12300.45, '#product-banner-wrapper');
     * </script>
     *
     * @param string totalPrice Celkova cena objednavky.
     * @param string selector CSS selector HTML elementu, do ktoreho bude vykresleny popis platobnej metody.
     */
    paymentMethodDescription(totalPrice, selector, promotionCode = this.PROMOTION_CODE_ODLOZTO, calculation = {}) {
        const productPromotionInfo = this.getInfo(promotionCode);

        const paymentDescriptionDiv = document.createElement('div');
        paymentDescriptionDiv.setAttribute('id', 'payment-info');
        paymentDescriptionDiv.classList.add('payment-info');

        // List group
        const list = document.createElement('ul');
        list.classList.add('payment-info__list');

        if (this.isShoppingLimitCommonForProspect(productPromotionInfo.code)) {
            list.innerHTML = '<li>bez úrokov a poplatkov</li>';
            list.innerHTML +=
                `<li>na nákup od ${productPromotionInfo.minGoodsPrice} do ` +
                productPromotionInfo.maxGoodsPriceProspect +
                '€</li>';
            if (productPromotionInfo.productType === this.PRODUCT_TYPE_CODE_ODLOZTO) {
                list.innerHTML += '<li>zaplať kedykoľvek do 30 dní od doručenia</li>';
            }
        } else {
            list.innerHTML = '<li>zadarmo a bez úrokov</li>';
            list.innerHTML +=
                `<li>ak si nový klient, nakupuj od ${productPromotionInfo.minGoodsPrice} do ` +
                productPromotionInfo.maxGoodsPriceProspect +
                ' €; ak sa už poznáme, nakupuj veselo do ' +
                productPromotionInfo.maxGoodsPrice +
                ' €</li>';
        }

        if (productPromotionInfo.productType === this.PRODUCT_TYPE_CODE_ROZLOZTO) {
            list.innerHTML += '<li>mesačné platby</li>';
        }

        paymentDescriptionDiv.appendChild(list);

        if (productPromotionInfo.productType === this.PRODUCT_TYPE_CODE_ROZLOZTO) {
            const instalmentAmountRaw = calculation && calculation.instalment ? calculation.instalment.amount : 0;
            const instalmentAmount = this._formatPrice(instalmentAmountRaw);
            const lastInstalmentAmount = this._formatPrice(
                calculation && calculation.lastInstalment ? calculation.lastInstalment.amount : instalmentAmountRaw,
            );
            // Ammount payment info
            const ammountPaymentList = document.createElement('ul');
            ammountPaymentList.classList.add('payment-info__ammount-payment');
            ammountPaymentList.innerHTML =
                `<li><span>1</span><strong>${instalmentAmount}</strong><small>Zaplatíš na ${productPromotionInfo.instalmentIntervalDays}. deň po prevzatí tovaru</small></li>` +
                `<li><span>2</span><strong>${instalmentAmount}</strong><small>Zaplatíš k ${productPromotionInfo.instalmentDayOfMonth}. dňu nasledujúceho mesiaca</small></li>` +
                `<li><span>3</span><strong>${lastInstalmentAmount}</strong><small>Zaplatíš k ${productPromotionInfo.instalmentDayOfMonth}. dňu ďalšieho mesiaca</small></li>`;
            paymentDescriptionDiv.appendChild(ammountPaymentList);
        }

        // Description
        const description = document.createElement('p');
        description.classList.add('payment-info__description');
        description.innerHTML =
            'Po odoslaní objednávky ťa presmerujeme na Ahoj, kde <br/> prejdeš rýchlym overením a podpíšeš zmluvu online.';
        paymentDescriptionDiv.appendChild(description);

        // Additional information
        const additionalInfo = document.createElement('p');
        additionalInfo.classList.add('payment-info__additional-info');
        additionalInfo.innerHTML =
            'Chcem využiť službu ' +
            (productPromotionInfo.productType === this.PRODUCT_TYPE_CODE_ODLOZTO
                ? 'Kúp teraz, zaplať o ' + productPromotionInfo.instalmentIntervalDays + ' dní'
                : 'Zaplatiť v 3 splátkach bez navýšenia') +
            ', ktorú <br/>' +
            ' poskytuje Ahoj, a. s. <a href="' +
            this.MORE_INFO_LINK_MAP[productPromotionInfo.productType] +
            '" target="_blank">Viac informácií.</a>';
        paymentDescriptionDiv.appendChild(additionalInfo);

        this._gtag('event', this.GTAG_SHOW_PAYMENT_METHOD_DESCR_EVENT);
        document.querySelector(selector).innerHTML = '';
        document.querySelector(selector).appendChild(paymentDescriptionDiv);
    }

    /**
     * ---------------------------------------------------------------------------------------------------------------
     * Public helper methods
     * ---------------------------------------------------------------------------------------------------------------
     */

    closeApplicationIframeModal() {
        const iframeModal = document.querySelector(`#${this.IFRAME_MODAL_ID}`);

        if (iframeModal) {
            iframeModal.parentNode.removeChild(iframeModal);
            document.body.classList.remove('ahoj-modal-scrollblock');
        } else {
            return false;
        }
    }

    closeHowItWorksModal() {
        const howItWorksModal = document.querySelector('#how-it-works');

        if (howItWorksModal) {
            howItWorksModal.parentNode.removeChild(howItWorksModal);
        } else {
            return false;
        }
    }

    getInfo(promotionCode = this.PROMOTION_CODE_ODLOZTO) {
        const promotionInfo = this.getInfoForAvailableProducts() || [];
        const productPromotionInfo = promotionInfo.find((info) => info.code === promotionCode);
        return productPromotionInfo ? productPromotionInfo : null;
    }

    getInfoForAvailableProducts() {
        if (this.promotionInfo === null) {
            return null;
        }
        if (this.promotionInfo['code']) {
            return [this.promotionInfo];
        }
        return this.promotionInfo;
    }

    isAvailable(promotionCode = this.PROMOTION_CODE_ODLOZTO) {
        const productPromotionInfo = this.getInfo(promotionCode);
        return productPromotionInfo != null;
    }

    isAvailableForTotalOrderPrice(totalOrderPrice, promotionCode = this.PROMOTION_CODE_ODLOZTO) {
        const productPromotionInfo = this.getInfo(promotionCode);
        return (
            productPromotionInfo != null &&
            productPromotionInfo.minGoodsPrice <= totalOrderPrice &&
            productPromotionInfo.maxGoodsPrice >= totalOrderPrice
        );
    }

    isAvailableForItemPrice(price, promotionCode = this.PROMOTION_CODE_ODLOZTO) {
        const productPromotionInfo = this.getInfo(promotionCode);
        return (
            productPromotionInfo != null &&
            productPromotionInfo.minGoodsItemPrice <= price &&
            productPromotionInfo.maxGoodsPrice >= price
        );
    }

    isAnyProductAvailableForItemPrice(price) {
        const promotionInfo = this.getInfoForAvailableProducts();
        if (promotionInfo === null) {
            return false;
        }
        for (let productPromotionInfo of promotionInfo) {
            if (this.isAvailableForItemPrice(price, productPromotionInfo.code)) {
                return true;
            }
        }
        return false;
    }

    isShoppingLimitCommonForProspect(promotionCode = this.PROMOTION_CODE_ODLOZTO) {
        const productPromotionInfo = this.getInfo(promotionCode);

        return (
            productPromotionInfo != null &&
            productPromotionInfo.maxGoodsPriceProspect == productPromotionInfo.maxGoodsPrice
        );
    }

    // gtag analytics
    enableAnalytics() {
        window['ga-disable-' + this.gtagMeasurementId] = false;
        this.disableAnalytics = false;
    }

    disableAnalytics() {
        window['ga-disable-' + this.gtagMeasurementId] = true;
        this.disableAnalytics = true;
    }

    /**
     * ---------------------------------------------------------------------------------------------------------------
     * Private methods
     * ---------------------------------------------------------------------------------------------------------------
     */
    _findProductCalculation(calculations, promotionCode) {
        const calc = calculations.find((calc) => calc.promotionCode === promotionCode);
        return calc || {};
    }

    _createApplicationIframeModal(applicationUrl) {
        const iframe = document.createElement('iframe');
        iframe.setAttribute('src', applicationUrl);
        iframe.style.width = '100%';
        iframe.style.height = '100%';
        iframe.style.border = '0';

        const modalDiv = document.createElement('div');
        modalDiv.setAttribute('id', this.IFRAME_MODAL_ID);
        modalDiv.classList.add('ahoj-modal');

        const modalDivInner = document.createElement('div');
        modalDivInner.classList.add('ahoj-modal-inner-div');

        modalDivInner.appendChild(iframe);
        modalDiv.appendChild(modalDivInner);
        document.body.classList.add('ahoj-modal-scrollblock');
        document.body.appendChild(modalDiv);
    }

    _getProductBannerTitle(promotionCode = this.PROMOTION_CODE_ODLOZTO, calculations = []) {
        const productPromotionInfo = this.getInfo(promotionCode);
        const calculation = this._findProductCalculation(calculations, promotionCode);
        switch (productPromotionInfo.productType) {
            case this.PRODUCT_TYPE_CODE_ROZLOZTO:
                const amount = this._formatPrice(calculation.instalment.amount);
                return `v <strong>3</strong> platbách po <strong>${amount}</strong> bez navýšenia`;
            default:
                const days = productPromotionInfo.instalmentIntervalDays;
                return `o <strong>${days} dní</strong> bez navýšenia`;
        }
    }

    isRozloztoAvailable(promotionCodeCalculation, price, code) {
        return (
            price && // price is available
            this.isAvailableForItemPrice(price, code) && // rozlozto is available for this price
            promotionCodeCalculation?.instalmentCount > 0 // code calculation has instalment count
        );
    }

    _createProductBannerElement(price, calculations) {
        const promotionInfos = this.getInfoForAvailableProducts();
        const productBannerDiv = document.createElement('div');
        productBannerDiv.classList.add('product-banner');

        const titleDiv = document.createElement('div');
        titleDiv.classList.add('product-banner__row');
        productBannerDiv.appendChild(titleDiv);

        const title = document.createElement('div');
        title.classList.add(this.productBannerTitleCssClass);
        title.innerHTML = `Kúp teraz, zaplať neskôr`;
        titleDiv.appendChild(title);

        for (let index = 0; index < promotionInfos.length; index++) {
            const info = promotionInfos[index];
            const promotionCodeCalculation = this._findProductCalculation(calculations, info.code);

            const isOdloztoAvailable =
                info.productType === this.PRODUCT_TYPE_CODE_ODLOZTO && this.isAvailableForItemPrice(price, info.code);
            const isOdloztoAvailableLegacy =
                info.code === this.PROMOTION_CODE_ODLOZTO && this.isAvailableForItemPrice(price, info.code);
            if (isOdloztoAvailable || isOdloztoAvailableLegacy) {
                const payLater = document.createElement('div');
                payLater.classList.add('product-banner__info');
                payLater.innerHTML = this._getProductBannerTitle(info.code, calculations);
                titleDiv.appendChild(payLater);

                const aLinkPayLater = document.createElement('a');
                aLinkPayLater.classList.add('product-banner__link');
                aLinkPayLater.href = 'javascript:void(0)';
                aLinkPayLater.onclick = () => {
                    this._gtag('event', this.GTAG_SHOW_HOW_IT_WORKS_EVENT);
                    this._createHowItWorksModal(info.code, calculations, price);
                };
                payLater.appendChild(aLinkPayLater);
            }

            if (
                info.productType === this.PRODUCT_TYPE_CODE_ROZLOZTO &&
                this.isRozloztoAvailable(promotionCodeCalculation, price, info.code)
            ) {
                const payThreeInstalments = document.createElement('div');
                payThreeInstalments.classList.add('product-banner__info');
                payThreeInstalments.innerHTML = this._getProductBannerTitle(info.code, calculations);
                titleDiv.appendChild(payThreeInstalments);

                const aLinkPayThreeInstalments = document.createElement('a');
                aLinkPayThreeInstalments.classList.add('product-banner__link');
                aLinkPayThreeInstalments.href = 'javascript:void(0)';
                aLinkPayThreeInstalments.onclick = () => {
                    this._gtag('event', this.GTAG_SHOW_HOW_IT_WORKS_EVENT);
                    this._createHowItWorksModal(info.code, calculations, price);
                };
                payThreeInstalments.appendChild(aLinkPayThreeInstalments);
            }
        }

        const logo = document.createElement('div');
        logo.classList.add('product-banner__logo');
        productBannerDiv.appendChild(logo);

        return productBannerDiv;
    }

    _createHowItWorksModalOdlozto(rootNode, productPromotionInfo, isActive) {
        const payLaterTitle = document.createElement('button');
        payLaterTitle.classList.add('how-it-works__payment-title', 'accordion-trigger');
        payLaterTitle.setAttribute('type', 'button');
        payLaterTitle.innerHTML = 'o 30 dní bez navýšenia';
        payLaterTitle.onclick = (e) => {
            e.preventDefault();
            payLaterTitle.classList.toggle('is-active');
            payLaterTitle.nextElementSibling.classList.toggle('is-active');
        };
        rootNode.appendChild(payLaterTitle);

        const payLaterContent = document.createElement('div');
        payLaterContent.classList.add('how-it-works__payment-content');
        rootNode.appendChild(payLaterContent);

        if (isActive) {
            payLaterTitle.classList.add('is-active');
            payLaterContent.classList.add('is-active');
        }

        const payLaterList = document.createElement('ul');
        payLaterList.classList.add('how-it-works__payment-list');

        if (this.isShoppingLimitCommonForProspect(productPromotionInfo.code)) {
            payLaterList.innerHTML =
                '<li>bez úroku a poplatkov</li>' +
                `<li>na nákup od ${productPromotionInfo.minGoodsPrice} € do ` +
                productPromotionInfo.maxGoodsPriceProspect +
                ' €</li>' +
                '<li>zaplať kedykoľvek do 30 dní od doručenia</li>';
        } else {
            payLaterList.innerHTML =
                '<li>zadarmo a bez navýšenia</li>' +
                `<li>zaplať kedykoľvek počas ${productPromotionInfo.instalmentIntervalDays}&nbsp;dní` +
                ` od doručenia tovaru. Ak si nový klient, nakupuj od` +
                productPromotionInfo.minGoodsPrice` do ` +
                productPromotionInfo.maxGoodsPriceProspect +
                ' € ak sa už poznáme, nakupuj veselo do ' +
                productPromotionInfo.maxGoodsPrice +
                ' €</li>';
        }
        payLaterContent.appendChild(payLaterList);

        const payLaterProcess = document.createElement('p');
        payLaterProcess.classList.add('how-it-works__payment-process');
        payLaterProcess.innerHTML =
            'Vlož do košíka, po čom ti srdce piští. ' +
            'Pri spôsobe platby vyber <span class="how-it-works__payment-process-logo"></span> ' +
            'Prejdi rýchlym overením a podpíš zmluvu online. ' +
            'Zaplať kedykoľvek do 30&nbsp;dní od doručenia.';
        payLaterContent.appendChild(payLaterProcess);
    }

    _createHowItWorksModalRozlozto(rootNode, productPromotionInfo, isActive, calculation) {
        const instalmentAmount = this._formatPrice(
            calculation && calculation.instalment ? calculation.instalment.amount : 0,
        );
        const instalmentAmountRaw = calculation && calculation.instalment ? calculation.instalment.amount : 0;
        const lastInstalmentAmount = this._formatPrice(
            calculation && calculation.lastInstalment ? calculation.lastInstalment.amount : instalmentAmountRaw,
        );

        const payThreeInstalmentsTitle = document.createElement('button');
        payThreeInstalmentsTitle.classList.add('how-it-works__payment-title', 'accordion-trigger');
        payThreeInstalmentsTitle.setAttribute('type', 'button');
        payThreeInstalmentsTitle.innerHTML = `v ${productPromotionInfo.instalmentCount.from} platbách bez navýšenia`;
        payThreeInstalmentsTitle.onclick = () => {
            payThreeInstalmentsTitle.classList.toggle('is-active');
            payThreeInstalmentsTitle.nextElementSibling.classList.toggle('is-active');
        };
        rootNode.appendChild(payThreeInstalmentsTitle);

        const payThreeInstalmentsContent = document.createElement('div');
        payThreeInstalmentsContent.classList.add('how-it-works__payment-content');
        rootNode.appendChild(payThreeInstalmentsContent);

        if (isActive) {
            payThreeInstalmentsTitle.classList.add('is-active');
            payThreeInstalmentsContent.classList.add('is-active');
        }

        const payThreeInstalmentsList = document.createElement('ul');
        payThreeInstalmentsList.classList.add('how-it-works__payment-list');

        if (this.isShoppingLimitCommonForProspect(productPromotionInfo.code)) {
            payThreeInstalmentsList.innerHTML =
                '<li>bez úrokov a poplatkov</li>' +
                `<li>na nákup od ${productPromotionInfo.minGoodsPrice} € do ` +
                productPromotionInfo.maxGoodsPriceProspect +
                ' €</li>' +
                `<li>pohodlne si rozdeľ sumu na ${productPromotionInfo.instalmentCount.from} rovnaké časti</li>`;
        } else {
            payThreeInstalmentsList.innerHTML =
                '<li>zadarmo a bez navýšenia</li>' +
                `<li>ak si nový klient, nakupuj od ${productPromotionInfo.minGoodsPrice} € do ` +
                productPromotionInfo.maxGoodsPriceProspect +
                '€ ak sa už poznáme, nakupuj veselo do ' +
                productPromotionInfo.maxGoodsPrice +
                ' €</li>' +
                `<li>pohodlne si rozdeľ sumu na ${productPromotionInfo.instalmentCount.from} rovnaké časti</li>`;
        }
        payThreeInstalmentsContent.appendChild(payThreeInstalmentsList);

        const payThreeInstalmentsGraphImg = document.createElement('div');
        payThreeInstalmentsGraphImg.classList.add('how-it-works__payment-graph-img');
        payThreeInstalmentsContent.appendChild(payThreeInstalmentsGraphImg);

        const payThreeInstalmentsGraph = document.createElement('ul');
        payThreeInstalmentsGraph.classList.add('how-it-works__payment-graph');
        payThreeInstalmentsGraph.innerHTML =
            `<li><strong>${instalmentAmount}</strong><small>Zaplatíš na ${productPromotionInfo.instalmentIntervalDays}.deň po prevzatí tovaru</small></li>` +
            `<li><strong>${instalmentAmount}</strong><small>Zaplatíš k ${productPromotionInfo.instalmentDayOfMonth}.dňu nasledujúceho mesiaca</small></li>` +
            `<li><strong>${lastInstalmentAmount}</strong><small>Zaplatíš k ${productPromotionInfo.instalmentDayOfMonth}.dňu ďalšieho mesiaca</small></li>`;
        payThreeInstalmentsContent.appendChild(payThreeInstalmentsGraph);

        const payThreeInstalmentsProcess = document.createElement('p');
        payThreeInstalmentsProcess.classList.add('how-it-works__payment-process');
        payThreeInstalmentsProcess.innerHTML =
            'Vlož do košíka, po čom ti srdce piští. ' +
            'Pri spôsobe platby vyber <span class="how-it-works__payment-process-logo"></span> ' +
            'Prejdi rýchlym overením a podpíš zmluvu online. ' +
            `Zaplať v ${productPromotionInfo.instalmentCount.from} pohodlných platbách.`;
        payThreeInstalmentsContent.appendChild(payThreeInstalmentsProcess);
    }

    /**
     * Displays "Ako to funguje" modal window
     */
    _createHowItWorksModal(promotionInfo, calculations, price) {
        const promotionInfos = this.getInfoForAvailableProducts();

        const howItWorksDiv = document.createElement('div');
        howItWorksDiv.setAttribute('id', 'how-it-works');

        const howItWorkContent = document.createElement('div');
        howItWorkContent.classList.add('how-it-works__content');
        howItWorksDiv.appendChild(howItWorkContent);

        // Close modal
        const howItWorkClose = document.createElement('a');
        howItWorkClose.classList.add('how-it-works__close');
        howItWorkClose.href = 'javascript:void(0)';
        howItWorkClose.onclick = () => {
            this.closeHowItWorksModal();
        };
        howItWorkContent.appendChild(howItWorkClose);

        // logo "Ahoj"
        const logo = document.createElement('div');
        logo.classList.add('how-it-works__logo');
        howItWorkContent.appendChild(logo);

        // Title
        const title = document.createElement('div');
        title.classList.add('how-it-works__title');
        title.innerHTML = 'Kúp teraz, <br/>' + ' <span>zaplať neskôr</span>';
        howItWorkContent.appendChild(title);

        // Description
        const description = document.createElement('div');
        description.classList.add('how-it-works__description');
        description.innerHTML =
            'Ahoj, prinášam ti nový zážitok z nakupovania! <br/>' +
            'So mnou si užívaš svoje nákupy hneď a platíš neskôr. <br/>' +
            'A čo je najlepšie, neminieš zo svojho ani cent.';
        howItWorkContent.appendChild(description);

        const typePaymentsContent = document.createElement('div');
        typePaymentsContent.classList.add('how-it-works__type-payments');
        typePaymentsContent.setAttribute('id', 'accordion');
        howItWorkContent.appendChild(typePaymentsContent);

        for (let index = 0; index < promotionInfos.length; index++) {
            const info = promotionInfos[index];
            const calculation = this._findProductCalculation(calculations, info.code);

            if (info.productType === this.PRODUCT_TYPE_CODE_ODLOZTO) {
                this._createHowItWorksModalOdlozto(typePaymentsContent, info, info.code === promotionInfo);
            } else if (
                info.productType === this.PRODUCT_TYPE_CODE_ROZLOZTO &&
                this.isRozloztoAvailable(calculation, price, info.code)
            ) {
                this._createHowItWorksModalRozlozto(
                    typePaymentsContent,
                    info,
                    info.code === promotionInfo,
                    calculation,
                );
            }
        }

        // Bank info flag
        const bankFlag = document.createElement('div');
        bankFlag.classList.add('how-it-works__bank-flag');
        bankFlag.innerHTML = 'člen skupiny' + '<br/><strong>365.bank</strong>';
        howItWorkContent.appendChild(bankFlag);

        document.body.appendChild(howItWorksDiv);
    }

    _formatPrice(price) {
        return Number(price).toLocaleString('sk', { style: 'currency', currency: 'EUR' });
    }

    _includeCssStyles() {
        const currentScriptSrc = document.currentScript.src;
        const pathArr = currentScriptSrc.split('/');
        pathArr.pop();
        pathArr.push('css');
        pathArr.push('main.css');
        const cssUrl = pathArr.join('/');
        const cssId = encodeURI(cssUrl);
        if (!document.getElementById(cssId)) {
            const head = document.getElementsByTagName('head')[0];
            const link = document.createElement('link');
            link.id = cssId;
            link.rel = 'stylesheet';
            link.type = 'text/css';
            link.href = cssUrl;
            link.media = 'all';
            head.appendChild(link);
        }
    }

    _includeAnalyticsJs() {
        this._includeJsFile('https://www.googletagmanager.com/gtag/js?id=' + this.gtagMeasurementId, () => {
            this.gtagInitialized = true;
            this._gtag('js', new Date());
            this._gtag('config', this.gtagMeasurementId, { send_page_view: false });
            this._flushGtagBuffer();
        });
    }

    _includeJsFile(url, callback) {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        if (callback) {
            script.onreadystatechange = () => {
                if (this.readyState == 'complete') {
                    callback();
                }
            };
            script.onload = callback;
        }
        document.body.appendChild(script);
    }

    _flushGtagBuffer() {
        if (this.gtagBuffer.length > 0) {
            this.gtagBuffer.forEach((val) => this._gtag.apply(this, val));
        }
    }

    _gtag() {
        if (!this.gtagInitialized) {
            this.gtagBuffer.push(arguments);
            return;
        }
        window.dataLayer = window.dataLayer || [];
        dataLayer.push(arguments);
    }

    _subscribeToMessages() {
        window.addEventListener('message', (event) => {
            if (this._isObject(event.data) && event.data.type) {
                switch (event.data.type) {
                    case 'redirect':
                        this.closeApplicationIframeModal();
                        if (event.data.url) {
                            window.location.replace(event.data.url);
                            return;
                        }
                        break;
                    case 'closeIframe':
                        this.closeApplicationIframeModal();
                        break;
                    case 'analytics':
                        this._gtag.apply(event.data);
                        break;
                }
            }
        });
    }

    _isObject(value) {
        return typeof value === 'object' && value !== null;
    }

    _logError(err) {
        console.error(`[${this.appName}]`, err);
    }
}

const ahojpayInstance = new AhojPay();
export default ahojpayInstance;
